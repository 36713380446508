import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ContactInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__contact-address-area mb-50 mt-50">
        <div className="container">
          <h1 className="text-align-center">Contact Us</h1>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-4 mb-20">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/10.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Email Address</h3>
                <p>
                  <a href="mailto:1100salsburydrive@gmail.com">
                    1100salsburydrive@gmail.com
                  </a>
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/11.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Phone Number</h3>
                <p>
                  +0123-456789 <br /> +987-6543210
                </p>
              </div>
            </div> */}
            <div className="col-lg-4 mb-20">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img
                    src={publicUrl + "assets/img/icons/12.png"}
                    alt="Icon Image"
                  />
                </div>
                <h3>Address</h3>
                <p>
                  1100 Salsbury Drive <br />
                  Vancouver, BC, Canada
                </p>
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
