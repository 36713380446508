import React, { useLayoutEffect } from "react";
import Navbar from "./global-components/navbar-v2";
import Banner from "./section-components/banner-v2";
import Aboutv3 from "./section-components/about-v3";
import Video from "./section-components/video-v2";
import Features from "./section-components/features-v1";
import UpComingProduct from "./section-components/upcoming-product-v1";
import ApartmentV2 from "./section-components/apartment-v2";
import ProductSlider from "./section-components/product-slider-v2";
import Availability from "./section-components/availability";
import Neighbour from "./section-components/neighbour";
import Category from "./section-components/category-v2";
import Testimonial from "./section-components/testimonial-v2";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import CoverImage from "./section-components/cover-image";
import ContactInfo from "./section-components/contact-info";
import ContactForm from "./section-components/contact-form";
import Map from "./section-components/map";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import scrollTo from "./scrollTo";
import { Helmet } from "react-helmet";

const Home_V2 = () => {
  const { hash } = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hash === "") {
        window.scrollTo(0, 0);
      }
      // else scroll to id
      else {
        const id = hash.replace("#", "");
        scrollTo(id);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hash === "") {
        window.scrollTo(0, 0);
      }
      // else scroll to id
      else {
        const id = hash.replace("#", "");
        scrollTo(id);
      }
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, [hash]);

  return (
    <div>
      <Helmet>
        <title>
          1100 Salsbury Drive - Modern Living off the Drive in East Vancouver
        </title>
        <meta
          name="description"
          content="1100 Salsbury Drive, Find your dream home in East Vancouver. Discover peaceful and luxurious living with modern amenities and stunning city views. Choose from a variety of spacious suites."
        />
      </Helmet>
      <Navbar />
      <Banner />
      <Aboutv3 />
      <CoverImage />
      <Video />
      {/* <Features customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---" /> */}
      {/* <UpComingProduct /> */}
      <ApartmentV2 />
      {/* <ProductSlider />
      <Availability /> */}
      <Neighbour />
      {/* <Category /> */}
      {/* <Testimonial /> */}
      {/* <BlogSlider /> */}
      <ContactInfo />
      <ContactForm />
      <Map />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default Home_V2;
