import React from "react";
import Navbar from "./global-components/navbar-v2";
import GalleryV2 from "./section-components/gallery-v2";
import Footer from "./global-components/footer";
import ContactInfo from "./section-components/contact-info";
import ContactForm from "./section-components/contact-form";
import Map from "./section-components/map";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Gallery - 1100 Salsbury Drive: Modern Living off the Drive in East
          Vancouver
        </title>
        <meta
          name="description"
          content="1100 Salsbury Drive, Find your dream home in East Vancouver. Discover peaceful and luxurious living with modern amenities and stunning city views. Choose from a variety of spacious suites."
        />
      </Helmet>
      <Navbar />
      <GalleryV2 />
      <ContactInfo />
      <ContactForm />
      <Map />
      <Footer />
    </div>
  );
};

export default Gallery;
