import React from "react";

const CoverImage = () => {
  return (
    <div className="pb-25 w-100 cover-image">
      <img src="assets/images/cover.jpg" alt="cover" />
    </div>
  );
};

export default CoverImage;
