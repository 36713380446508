import { shuffle } from "lodash";
import React from "react";

const images = [
  "assets/images/image-1.jpg",
  "assets/images/image-2.jpg",
  "assets/images/image-3.jpg",
  "assets/images/image-4.jpg",
  "assets/images/image-5.jpg",
  "assets/images/image-6.jpg",
  "assets/images/image-7.jpg",
  "assets/images/image-8.jpg",
  "assets/images/image-9.jpg",
  "assets/images/image-10.jpg",
  "assets/images/image-11.jpg",
  "assets/images/image-12.jpg",
  "assets/images/image-13.jpg",
];

const shuffledImages = shuffle(images);

const Gallery = () => {
  return (
    <div className="gallery-container">
      <div className="container">
        <div className="gallery-title-line"></div>
        <div className="gallery-title">
          <h1>Gallery</h1>
        </div>
        <div className="gallery-row-1-1">
          <div>
            <img
              className="gallery-image"
              src={shuffledImages[0]}
              alt="gallery"
            />
          </div>
          <div>
            <img
              className="gallery-image"
              src={shuffledImages[1]}
              alt="gallery"
            />
          </div>
        </div>
        <div className="gallery-row-2-1">
          <div>
            <img
              className="gallery-image"
              src={shuffledImages[2]}
              alt="gallery"
            />
          </div>
          <div className="gallery-column-1-1">
            <div>
              <img
                className="gallery-image"
                src={shuffledImages[3]}
                alt="gallery"
              />
            </div>
            <div>
              <img
                className="gallery-image"
                src={shuffledImages[4]}
                alt="gallery"
              />
            </div>
          </div>
        </div>
        <div className="gallery-row-1-1">
          <div>
            <img
              className="gallery-image"
              src={shuffledImages[5]}
              alt="gallery"
            />
          </div>
          <div>
            <img
              className="gallery-image"
              src={shuffledImages[6]}
              alt="gallery"
            />
          </div>
        </div>
      </div>
      <div className="gallery-reel">
        <div>
          <img
            className="gallery-image"
            src={shuffledImages[7]}
            alt="gallery"
          />
        </div>
        <div>
          <img
            className="gallery-image"
            src={shuffledImages[8]}
            alt="gallery"
          />
        </div>
        <div>
          <img
            className="gallery-image"
            src={shuffledImages[9]}
            alt="gallery"
          />
        </div>
        <div>
          <img
            className="gallery-image"
            src={shuffledImages[10]}
            alt="gallery"
          />
        </div>
        <div>
          <img
            className="gallery-image"
            src={shuffledImages[11]}
            alt="gallery"
          />
        </div>
        <div>
          <img
            className="gallery-image"
            src={shuffledImages[12]}
            alt="gallery"
          />
        </div>
      </div>
      <div className="gallery-footer">
        <h2>Love what you see here ?</h2>
        <p>You will even love it more when you experience it live.</p>
      </div>
    </div>
  );
};

export default Gallery;
