import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-115 pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-us-img-wrap about-img-left">
                <img src="assets/images/image-12.jpg" alt="About Us Image" />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src={publicUrl + "assets/img/others/8.png"}
                      alt="video popup bg image"
                    />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-30">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    About the living space
                  </h6>
                  <h2 className="section-title">Spacious Living Year-Round</h2>
                  <p>
                    1100 Salsbury Drive features modern updated suite amenities
                    including natural gas cooking, radiant hot water heating
                    systems, granite counter tops along with numerous wide
                    thermal windows providing for ample light and city views in
                    all season living.
                  </p>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-secure-shield" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>Exclusivity and Privacy</h4>
                    <p>
                      The entire property is surrounded by privacy hedges with
                      front and back ground level entertainment patios to host
                      parties in a quiet private setting.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-house-4" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>Captivating Natural Surroundings</h4>
                    <p>
                      Comfortable living just east of the Vancouver Business
                      District surrounded by old growth trees and offering
                      breathtaking views of the city skyline.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-maps-and-location" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>The Perfect Residency</h4>
                    <p>
                      Each suite has their own private entrances, separate
                      kitchens, bathrooms and bedroom facilities. Floor plans
                      include studio, one bed room, two bedroom and three
                      bedroom choices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV3;
