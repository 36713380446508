import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class VideoV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__about-us-area section-bg-1 bg-image-right-before pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    NEIGHBORHOOD ADVANTAGES
                  </h6>
                  <h2 className="section-title">
                    Modern Lifestyles Experiences Just Steps Away
                  </h2>
                  <p>
                    Commercial Drive provides a unique neighborhood shopping
                    experience for food, entertainment, relaxation and daily
                    life supports.
                  </p>
                </div>
                <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                  <li>
                    <i className="icon-done" />
                    Four neighborhood grocery stores
                  </li>
                  <li>
                    <i className="icon-done" />
                    Twenty unique restaurant experiences
                  </li>
                  <li>
                    <i className="icon-done" />
                    Two Workout Gyms and a community center
                  </li>
                  <li>
                    <i className="icon-done" />
                    Six Park green spaces with modern playgrounds
                  </li>
                  <li>
                    <i className="icon-done" />
                    Twelve Medical, Dental and physio practitioners
                  </li>
                  <li>
                    <i className="icon-done" />
                    Tailors, cobblers, dressmakers, and, dry cleaners
                  </li>
                </ul>
                <div className="  ltn__animation-pulse2 text-center mt-30">
                  <a
                    className="ltn__video-play-btn bg-white--- ltn__secondary-bg"
                    href="https://www.youtube.com/embed/Qu3ZOz_U_W4?autoplay=1&showinfo=0"
                    data-rel="lightcase"
                  >
                    <i className="icon-play  ltn__secondary-color--- white-color" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoV2;
