import React, { Component } from "react";
import { Link } from "react-router-dom";
import Copyright from "./copyright";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  footer-bg-1 plr--10">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-5 col-md-5 col-sm-12 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img src="assets/images/logo-light.png" alt="Logo" />
                    </div>
                  </div>
                  <p style={{ fontSize: 14 }}>
                    A well constructed property in peaceful and serene locality
                    that guarantees you the best and most enchanting experience
                    of luxury living in the Centre of East Vancouver.
                  </p>
                  {/* <div className="ltn__social-media mt-20">
                    <Social />
                  </div> */}
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-4 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  {/* <h4 className="footer-title">Company</h4> */}
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/#neighborhood">Neighborhood</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-sm-4 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  {/* <h4 className="footer-title">Services</h4> */}
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-sm-4 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  {/* <h4 className="footer-title">Customer Care</h4> */}
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/#contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Copyright />
      </footer>
    );
  }
}

export default Footer_v1;
