import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Neighbour extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="neighbour-area section-bg-1 pt-118 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title-area ltn__section-title-2--- text-center---"
                id="neighborhood"
              >
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  Explore Neighbour
                </h6>
                <h1 className="section-title">
                  What's In Neighbour <br />
                  Explore Below
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__neighbour-tab-wrap">
                <div className="ltn__tab-menu ltn__tab-menu-3--- ltn__tab-menu-4 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                  <div className="nav">
                    <a
                      className="active show"
                      data-bs-toggle="tab"
                      href="#liton_tab_4_1"
                    >
                      <img src="assets/images/image-26.png" alt="#" />
                    </a>
                    <a data-bs-toggle="tab" href="#liton_tab_4_2">
                      <img src="assets/images/image-24.png" alt="#" />
                    </a>
                    <a data-bs-toggle="tab" href="#liton_tab_4_3">
                      <img src="assets/images/image-25.png" alt="#" />
                    </a>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="liton_tab_4_1">
                    <div className="ltn__neighbour-tab-content-inner">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="neighbour-apartments-img">
                            <img src="assets/images/image-26.png" alt="#" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="ltn__search-by-place-item neighbour-apartments-item">
                            <div className="search-by-place-img">
                              <img src="assets/images/image-27.png" alt="#" />
                            </div>
                            <div className="search-by-place-info go-top">
                              <h4>Downtown/West Van/ UBC</h4>
                              <label>
                                <span className="ltn__secondary-color">
                                  4.1 km{" "}
                                </span>
                                / 13 min. drive
                              </label>
                              <div className="search-by-place-brief">
                                <p>
                                  Downtown Vancouver, West Vancouver, and the
                                  University of British Columbia (UBC) form a
                                  unique trio that spans urban excitement,
                                  coastal beauty, and academic excellence, all
                                  within close proximity to each other. Whether
                                  you're exploring the vibrant city center,
                                  enjoying the serene coastal views, or
                                  immersing yourself in UBC's renowned campus,
                                  this interconnected region offers a diverse
                                  and enriching experience for residents and
                                  visitors alike.
                                </p>
                              </div>
                              <div className="search-by-place-btn ">
                                <Link
                                  to={{
                                    pathname:
                                      "https://goo.gl/maps/qBGpwuwNNSAeAC9RA",
                                  }}
                                  target="_blank"
                                >
                                  View in Map{" "}
                                  <i className="flaticon-right-arrow" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="liton_tab_4_2">
                    <div className="ltn__neighbour-tab-content-inner">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="neighbour-apartments-img">
                            <img src="assets/images/image-24.png" alt="#" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="ltn__search-by-place-item neighbour-apartments-item">
                            <div className="search-by-place-img">
                              <img src="assets/images/image-28.png" alt="#" />
                            </div>
                            <div className="search-by-place-info go-top">
                              <h4>Burnaby/Willington/Metrotown</h4>
                              <label>
                                <span className="ltn__secondary-color">
                                  9.7 km{" "}
                                </span>
                                / 21 min. drive
                              </label>
                              <div className="search-by-place-brief">
                                <p>
                                  Burnaby, Willingdon, and Metrotown come
                                  together to create a vibrant urban area known
                                  for its diverse offerings. From the bustling
                                  Metrotown shopping district to the cultural
                                  attractions and green spaces in Burnaby and
                                  Willingdon, this dynamic region provides a
                                  rich tapestry of entertainment, shopping, and
                                  recreational opportunities.
                                </p>
                              </div>
                              <div className="search-by-place-btn ">
                                <Link
                                  to={{
                                    pathname:
                                      "https://goo.gl/maps/49NiQDfU4fnRUkmZ8",
                                  }}
                                  target="_blank"
                                >
                                  View in Map{" "}
                                  <i className="flaticon-right-arrow" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="liton_tab_4_3">
                    <div className="ltn__neighbour-tab-content-inner">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="neighbour-apartments-img">
                            <img src="assets/images/image-25.png" alt="#" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="ltn__search-by-place-item neighbour-apartments-item">
                            <div className="search-by-place-img">
                              <img src="assets/images/image-29.png" alt="#" />
                            </div>
                            <div className="search-by-place-info go-top">
                              <h4>Kitsilano / Kerrisdale / Southlands</h4>
                              <label>
                                <span className="ltn__secondary-color">
                                  8.8 km{" "}
                                </span>
                                / 21 min. drive
                              </label>
                              <div className="search-by-place-brief">
                                <p>
                                  Kitsilano, Kerrisdale, and Southlands offer a
                                  charming blend of residential tranquility and
                                  vibrant community life on Vancouver's west
                                  side. From the picturesque beaches and outdoor
                                  activities in Kitsilano to the charming
                                  village atmosphere and upscale boutiques in
                                  Kerrisdale, these neighborhoods showcase a
                                  unique mix of natural beauty and urban
                                  amenities.
                                </p>
                              </div>
                              <div className="search-by-place-btn ">
                                <Link
                                  to={{
                                    pathname:
                                      "https://goo.gl/maps/ADi3eZVfGj5EHxgv8",
                                  }}
                                  target="_blank"
                                >
                                  View in Map{" "}
                                  <i className="flaticon-right-arrow" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Neighbour;
