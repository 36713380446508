import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ApartmentV2 extends Component {
  render() {
    let CustomClass = this.props.customClass ? this.props.customClass : "";

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className={"ltn__apartments-plan-area pt-115 pb-70 " + CustomClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">Apartments Plan</h1>
              </div>
              <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav">
                  <a data-bs-toggle="tab" href="#liton_tab_3_1">
                    Studios
                  </a>
                  <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_2"
                  >
                    Multi Bedroom
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_3">
                    Penthouse
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_4">
                    Common area
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_5">
                    Detached Space
                  </a>
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade" id="liton_tab_3_1">
                  <div className="ltn__apartments-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <div>
                            <h2>The Studios</h2>
                            <p>
                              Affordable monthly rental comes with enhanced
                              walkaround score close to shops and transit. These
                              comfortable open studios feature private
                              bathrooms, well appointed kitchens,
                              bedrooms/living rooms with inlaid hardwood floors,
                              and large double glazed Europeans style windows
                            </p>
                            <div className="apartments-info-list apartments-info-list-color mt-40">
                              <ul>
                                <li>
                                  <label>Suites</label>{" "}
                                  <span>
                                    15<sup>*</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Living Space</label>{" "}
                                  <span>
                                    37-167 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>3 City Duplex Lots</label>{" "}
                                  <span>
                                    350 m<sup>2</sup> each
                                  </span>
                                </li>
                                <li>
                                  <label>Land Surface</label>{" "}
                                  <span>
                                    1050 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Common Area</label>{" "}
                                  <span>
                                    92 m<sup>2</sup>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p style={{ fontSize: 12, opacity: 0.7 }}>
                            <sup>*</sup> 12 are legal suites and 3 are
                            unauthorized: The current city of Vancouver policy
                            of densification makes the unauthorized suite status
                            not an issue with the city.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img src="assets/images/image-18.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade active show" id="liton_tab_3_2">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <div>
                            <h2>Two & Three Bedroom </h2>
                            <p>
                              These elegant suites, with multiple private
                              bedrooms feature enhancements like high ceilings,
                              gas fireplaces and gas ranges, walk in multi-head
                              showers, modern appliances include garburators,
                              dishwashers and stainless steel sinks plus Bayview
                              windows revealing city and mountain views.
                            </p>
                            <div className="apartments-info-list apartments-info-list-color mt-40">
                              <ul>
                                <li>
                                  <label>Suites</label>{" "}
                                  <span>
                                    15<sup>*</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Living Space</label>{" "}
                                  <span>
                                    37-167 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>3 City Duplex Lots</label>{" "}
                                  <span>
                                    350 m<sup>2</sup> each
                                  </span>
                                </li>
                                <li>
                                  <label>Land Surface</label>{" "}
                                  <span>
                                    1050 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Common Area</label>{" "}
                                  <span>
                                    92 m<sup>2</sup>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p style={{ fontSize: 12, opacity: 0.7 }}>
                            <sup>*</sup> 12 are legal suites and 3 are
                            unauthorized: The current city of Vancouver policy
                            of densification makes the unauthorized suite status
                            not an issue with the city.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img src="assets/images/image-17.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_3">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <div>
                            <h2>Spectacular Space with Views</h2>
                            <p>
                              This 1450 sq ft unit has multiple rooftop open
                              spaces, custom patio furniture, retractable awning
                              and gas barbeque. The Master bedroom boasts
                              walk-in closets high ceiling, a spectacular
                              central chandelier, King size bed, study nook and
                              patio doors opening onto the roof space. Fully
                              equipped kitchen, gas range and double door fridge
                              with espresso machine and your own in suite
                              laundry.
                            </p>
                            <div className="apartments-info-list apartments-info-list-color mt-40">
                              <ul>
                                <li>
                                  <label>Suites</label>{" "}
                                  <span>
                                    15<sup>*</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Living Space</label>{" "}
                                  <span>
                                    37-167 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>3 City Duplex Lots</label>{" "}
                                  <span>
                                    350 m<sup>2</sup> each
                                  </span>
                                </li>
                                <li>
                                  <label>Land Surface</label>{" "}
                                  <span>
                                    1050 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Common Area</label>{" "}
                                  <span>
                                    92 m<sup>2</sup>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p style={{ fontSize: 12, opacity: 0.7 }}>
                            <sup>*</sup> 12 are legal suites and 3 are
                            unauthorized: The current city of Vancouver policy
                            of densification makes the unauthorized suite status
                            not an issue with the city.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img src="assets/images/image-21.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_4">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <div>
                            <h2>Social spaces with privacy</h2>
                            <p>
                              Grounds surrounding the building have front and
                              back 800+ sq foot custom concrete floor patios
                              each with barbeque units, community tables and a
                              variety of seating arrangements. Green spaces
                              includes lawns and shaded quiet areas, bike
                              storage and refuse/recycling areas. Three outside
                              garages house a live-workshop and a storage
                              facility. Security gates and video system protect
                              the property.
                            </p>
                            <div className="apartments-info-list apartments-info-list-color mt-40">
                              <ul>
                                <li>
                                  <label>Suites</label>{" "}
                                  <span>
                                    15<sup>*</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Living Space</label>{" "}
                                  <span>
                                    37-167 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>3 City Duplex Lots</label>{" "}
                                  <span>
                                    350 m<sup>2</sup> each
                                  </span>
                                </li>
                                <li>
                                  <label>Land Surface</label>{" "}
                                  <span>
                                    1050 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Common Area</label>{" "}
                                  <span>
                                    92 m<sup>2</sup>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p style={{ fontSize: 12, opacity: 0.7 }}>
                            <sup>*</sup> 12 are legal suites and 3 are
                            unauthorized: The current city of Vancouver policy
                            of densification makes the unauthorized suite status
                            not an issue with the city.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img src="assets/images/image-3.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_5">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <div>
                            <h2>Garage building</h2>
                            <p>
                              Three outside garages and storage facilities rents
                              at $200 per bay. Security gates and video system
                              protect the property.
                            </p>
                            <div className="apartments-info-list apartments-info-list-color mt-40">
                              <ul>
                                <li>
                                  <label>Suites</label>{" "}
                                  <span>
                                    15<sup>*</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Living Space</label>{" "}
                                  <span>
                                    37-167 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>3 City Duplex Lots</label>{" "}
                                  <span>
                                    350 m<sup>2</sup> each
                                  </span>
                                </li>
                                <li>
                                  <label>Land Surface</label>{" "}
                                  <span>
                                    1050 m<sup>2</sup>
                                  </span>
                                </li>
                                <li>
                                  <label>Common Area</label>{" "}
                                  <span>
                                    92 m<sup>2</sup>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p style={{ fontSize: 12, opacity: 0.7 }}>
                            <sup>*</sup> 12 are legal suites and 3 are
                            unauthorized: The current city of Vancouver policy
                            of densification makes the unauthorized suite status
                            not an issue with the city.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img src="assets/images/image-3.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApartmentV2;
