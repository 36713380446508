import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const images = [
  "assets/images/image-4.jpg",
  "assets/images/image-21.jpg",
  "assets/images/image-13.jpg",
  "assets/images/image-15.jpg",
  "assets/images/image-20.jpg",
];

const SliderImages = () => {
  return images.map((image, index) => (
    <div
      key={`bannerImage_${index}`}
      className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11"
    >
      <div className="ltn__slide-item-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <div className="slide-item-img">
                <img src={image} alt={`Banner ${index + 1}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};
class BannerV2 extends Component {
  render() {
    return (
      <div className="custom-banner">
        <div className="custom-banner-left">
          <div className="banner-content">
            <div className="slide-video mb-50 d-none">
              <a
                className="ltn__video-icon-2 ltn__video-icon-2-border"
                href="https://www.youtube.com/embed/Qu3ZOz_U_W4"
                data-rel="lightcase:myCollection"
              >
                <i className="fa fa-play" />
              </a>
            </div>
            <div className="animate__animated animate__delay-1s customFadeUp">
              <h1 className="slide-sub-title white-color---">
                1100 Salsbury Drive
              </h1>
              <h1 className="slide-title ">
                Modern Safe Comfortable
                <br />
                Living off Commercial Drive
                <br />
                <span>East Vancouver</span>
              </h1>
              <div className="slide-brief">
                <p>
                  A well constructed property on a sunny sloped hillside in a
                  peaceful and serene location that guarantees the best active
                  living experience.
                </p>
              </div>
            </div>
            <div className="header-cta-button-container btn-wrapper animate__animated animate__delay-1s customFadeUp">
              <div className="header-cta-button">
                <Link to="/gallery" className="theme-btn-1 btn btn-effect-1">
                  View Gallery
                </Link>
              </div>
              <a
                className="header-cta-button"
                href="https://www.youtube.com/embed/Qu3ZOz_U_W4?autoplay=1&showinfo=0"
                data-rel="lightcase"
              >
                <i className="icon-play  ltn__secondary-color" />
                <span>Watch Video</span>
              </a>
            </div>
          </div>
        </div>
        <div className="custom-banner-right">
          <div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1">
            <div className="ltn__slider-11-inner">
              <div className="ltn__slider-11-active">
                <SliderImages />
              </div>
              {/* slider-4-img-slide-arrow */}
              <div className="ltn__slider-11-img-slide-arrow">
                <div className="ltn__slider-11-img-slide-arrow-inner">
                  <div className="ltn__slider-11-img-slide-arrow-active">
                    {images.map((image, index) => (
                      <div
                        key={`bannerDots_${index}`}
                        className="image-slide-item"
                      >
                        <img src={image} alt={`Dots ${index + 1}`} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerV2;
