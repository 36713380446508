import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Map extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.0820075714796!2d-123.0701606237405!3d49.27484327139165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486713ef42d4f71%3A0x186e5d3315d5e23c!2s1100%20Salsbury%20Dr%2C%20Vancouver%2C%20BC%2C%20Canada!5e0!3m2!1sen!2sin!4v1685551356666!5m2!1sen!2sin"
          width="100%"
          height="100%"
          frameBorder={0}
          allowFullScreen
          aria-hidden="false"
          tabIndex={0}
        />
      </div>
    );
  }
}

export default Map;
