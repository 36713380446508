import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import validator from "validator";
import { startCase } from "lodash";
import toast, { Toaster } from "react-hot-toast";

const ContactForm = () => {
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const validateForm = () => {
    let errors = {};

    Object.keys(data).forEach((key) => {
      switch (key) {
        case "email":
          if (!validator.isEmail(data[key])) {
            errors = { ...errors, [key]: `Email is invalid` };
          }
          break;
        case "phone":
          if (!validator.isMobilePhone(data[key])) {
            errors = { ...errors, [key]: `Phone number is invalid` };
          }
          break;
        default:
          break;
      }

      if (!data[key]) {
        errors = { ...errors, [key]: `${startCase(key)} is required` };
      }
    });

    setErrors(errors);
    return Object.keys(errors).length !== 0;
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const sendMail = async (e) => {
    e.preventDefault();
    console.log("qweqw", data);
    const hasErrors = validateForm();

    if (!hasErrors) {
      try {
        setIsSending(true);

        await axios.post(
          "https://api.emailjs.com/api/v1.0/email/send",
          JSON.stringify({
            template_params: { ...data },
            user_id: process.env.REACT_APP_EMAIL_USER_ID,
            service_id: process.env.REACT_APP_EMAIL_SERVICE_ID,
            template_id: process.env.REACT_APP_EMAIL_QUERY_TEMPLATE_ID,
          }),
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );

        setIsSending(false);
        toast.success("Your data has been submitted successfully");
      } catch {
        toast.error("Oops! An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="ltn__contact-message-area mb-120 mb--100" id="contact">
      <Toaster
        position="bottom-center"
        toastOptions={{
          className: "customToast",
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Get a Quote</h4>
              <form id="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="firstName"
                        placeholder="Enter your first name"
                        value={data.firstName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Enter your last name"
                        value={data.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        value={data.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter phone number"
                        value={data.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    placeholder="Enter message"
                    value={data.message}
                    onChange={handleChange}
                  />
                </div>
                {errors &&
                  Object.values(errors).map((error) => (
                    <p className="form-error">{error}</p>
                  ))}
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1"
                    type="submit"
                    onClick={sendMail}
                    disabled={isSending}
                  >
                    {isSending ? "Sending..." : "Send"}
                  </button>
                </div>
                <p className="form-messege mb-0 mt-20" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
