import { scroller } from "react-scroll";

const scrollToSection = (id, offset = 120) => {
  scroller.scrollTo(id, {
    duration: 500,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -offset,
  });
};

export default scrollToSection;
